/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react'
import HeaderSearch from './HeaderSearch'
import './Header.css'
import axios from 'axios'
import { Link, NavLink, useHistory } from 'react-router-dom'
import { Button, Popover, ListItem, Divider, SwipeableDrawer } from '@material-ui/core'
import { handleRedirectInternal } from '../../../Product/common/components'
import AuthContext from '../../../Product/context/auth/authContext'
import pluginContext from '../../../Product/context/plugin/pluginContext'
import { useMediaQuery } from 'react-responsive'
import { Logo } from '../../../utils/index'
import CartIcon from '../../../utils/CommonFunctionality/Cart/CartIcon'
import CustomDialog from '../../../Product/components/organisms/Dialog'
import Popup from '../../../Product/components/organisms/Popup'
import Headernavmenu from '../../../utils/CommonFunctionality/HeaderNavigaton/headernavigation'
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from 'swiper/react/swiper-react'
import 'swiper/swiper-bundle.css'
import { EffectCoverflow, Navigation, Autoplay, Pagination } from 'swiper'
import pluginReducer from '../../../Product/context/plugin/pluginReducer'
import DualCart from '../../../utils/CommonFunctionality/DualCart/DualCart'
import { socketAuction } from '../../../Product/common/socket'
import productContext from '../../../Product/context/product/productContext'
const Header = (props) => {
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

    const history = useHistory()
    const [anchormenu, setanchormenu] = useState(null)
    const { isAuthenticated, logout, user, userCount, loadUser } = useContext(AuthContext)
    const ProductContext = useContext(productContext)
    const [buynowitems, setBuynowitems] = useState([])
    const { search_allproducts, getAllSearchProducts, productLocalState } = ProductContext
    const {
        request_become_seller,
        responseStatus: pluginstatus,
        clearResponse: clearpluginresponse,
    } = useContext(pluginContext)
    const [openModal, setModal] = useState(false)

    const isDesktop = useMediaQuery({
        query: '(min-width: 991px)',
    })
    const [state, setState] = useState({
        top: false,
        right: false,
    })

    const changeDialogStatus = () => {
        setModal(!openModal)
    }

    useEffect(() => {
        getAllSearchProducts(
            {
                sh_limit: 12,
                from: 'buynow',
                page: 1,
                orderby: '2',
                auction_io: 1,
                auction_city:
                    productLocalState.globalLocation == 'All'
                        ? []
                        : [productLocalState.globalLocation],
            },
            'galleryPage_prt',
        )
    }, [productLocalState.globalLocation])

    useEffect(() => {
        if (search_allproducts.from == 'galleryPage_prt') {
            setBuynowitems(
                search_allproducts?.results?.length > 0 ? search_allproducts.results : [],
            )
        }
    }, [search_allproducts])

    useEffect(() => {
        if (window.location.hostname === 'localhost') {
            axios.defaults.headers.common['domain'] = 'auction_preview.ecommerce.auction'
        }
    }, [])

    const handleMenu = (event) => {
        setanchormenu(event.currentTarget)
    }

    const handleClose = (history, path) => {
        setanchormenu(null)
        if (history && path && path !== 'backdropClick') {
            handleRedirectInternal(history, path)
        }
    }

    const logoutUser = () => {
        logout()
        history.push('/login')
        // window.location.reload()
    }

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    if (localStorage.getItem('pos')) {
        localStorage.removeItem('pos')
    }

    const confirm_become_seller = () => {
        request_become_seller({
            user_id: user.id,
            seller_user_id: global?.storeDetails?.user_id,
            email: user.email,
            firstime: user.isemp != 2 ? 1 : 0,
            store_site_id: global?.storeDetails?.site_id,
        })
    }

    useEffect(() => {
        if (pluginstatus) {
            if (pluginstatus.from === 'request_become_seller_from') {
                if (pluginstatus.status === 'success') {
                    if (user?.isemp != 2) {
                        loadUser()
                    }
                    window.open(pluginstatus.message)
                }
            }
            clearpluginresponse()
        }
    }, [pluginstatus])

    useEffect(() => {
        socketAuction.on('pluginMode', (data) => {
            if (global.storeDetails.site_id === data.site_id) {
                if (data.type === 'reverse_auction') {
                    if (parseInt(data.enable) == 0) {
                        // console.log('innnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn', data)
                        setAlert('Reverse Auction Disabled By Seller', 'warning')
                    }
                    setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                }
            }
        })
    }, [])

    useEffect(() => {
        if (window.location.href.includes('/reverse-auction')) {
            if (!global.pluginConfiguration?.reverse_auction?.enable) {
                window.location.href = window.location.href.includes(global.storeDetails.id)
                    ? `/${global.storeDetails.id}/search`
                    : `/search`
            }
        }
    }, [])

    return (
        <>
            {isDesktop ? (
                /* DESKTOP NAVIGATION */
                <>
                    <header className="mainHeader noPrint">
                        <div className="customContainer d-flex justify-content-between align-items-center h-100">
                            <div className="headLt d-flex justify-content-start align-items-center">
                                <Logo className="headerLogo" />
                            </div>
                            <HeaderSearch />
                            <div className="headRt d-flex justify-content-start align-items-center nowrap">
                                <ul
                                    id={
                                        global.storeDetails.header_theme == '#000000'
                                            ? 'change-bg-font-color'
                                            : ''
                                    }
                                    className="d-flex justify-content-start align-items-center"
                                >
                                    <Headernavmenu type="desk" />

                                    {isAuthenticated ? (
                                        <>
                                            {global.storeConfigration?.disable_buynow?.value !==
                                            '1' ? (
                                                <li>
                                                    {global.pluginConfiguration?.dual_cart
                                                        ?.enable == 1 ? (
                                                        <DualCart />
                                                    ) : (
                                                        <CartIcon />
                                                    )}
                                                </li>
                                            ) : null}
                                            <li>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/favorites"
                                                >
                                                    <img src="/lots-images/favourite.svg" alt="" />
                                                </NavLink>
                                            </li>

                                            <li>
                                                <Button
                                                    aria-controls="simple-menu"
                                                    aria-haspopup="true"
                                                    onClick={handleMenu}
                                                >
                                                    My Account
                                                    <span className="material-icons">
                                                        account_circle
                                                    </span>
                                                </Button>

                                                <Popover
                                                    id={'simple-menu'}
                                                    open={Boolean(anchormenu)}
                                                    anchorEl={anchormenu}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <ul className="headerDropMenu">
                                                        <ListItem
                                                            button
                                                            onClick={() =>
                                                                handleClose(
                                                                    history,
                                                                    'dashboard/mybids',
                                                                )
                                                            }
                                                        >
                                                            Dashboard
                                                        </ListItem>
                                                        {/* <ListItem
                                                        button
                                                        onClick={() => handleClose(history, 'cart')}
                                                    >
                                                        Cart
                                                    </ListItem> */}

                                                        <ListItem
                                                            button
                                                            onClick={() =>
                                                                handleClose(
                                                                    history,
                                                                    'dashboard/profile',
                                                                )
                                                            }
                                                        >
                                                            Profile
                                                        </ListItem>
                                                        <ListItem button onClick={logoutUser}>
                                                            Logout
                                                        </ListItem>
                                                    </ul>
                                                </Popover>
                                            </li>
                                        </>
                                    ) : (
                                        <li>
                                            <NavLink className="login-btn" to="/login">
                                                Login
                                            </NavLink>
                                            <NavLink className="signup-btn" to="/sign-up">
                                                Sign up
                                            </NavLink>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </header>
                    <div className="header-links-bottom">
                        <div className="customContainer d-flex justify-content-between align-items-center">
                            <ul
                                id={
                                    global.storeDetails.header_theme == '#000000'
                                        ? 'change-bg-font-color'
                                        : ''
                                }
                                className="menu-blinks w-100"
                            >
                                {global.storeConfigration?.external_schedule_pickup_link?.value && (
                                    <li>
                                        <a
                                            href="#"
                                            onClick={() => setModal(true)}
                                            className="cvc-link mr-2"
                                        >
                                            Schedule Pickup
                                        </a>
                                    </li>
                                )}
                                <li>
                                    <NavLink activeClassName="active" to="/auctions">
                                        Auctions
                                    </NavLink>
                                </li>
                                {global.storeConfigration?.disable_buynow?.value !== '1' &&
                                buynowitems.length > 0 ? (
                                    <li>
                                        <NavLink activeClassName="active" to="/gallery">
                                            {global.storeConfigration?.buy_now_change_text
                                                ?.value !== '1' &&
                                            global.storeConfigration?.buy_now_change_text?.value
                                                ? global.storeConfigration.buy_now_change_text.value
                                                : global.storeConfigration?.buy_now_change_text
                                                      ?.value !== '1'
                                                ? 'Buy Now'
                                                : ''}
                                        </NavLink>
                                    </li>
                                ) : null}
                                <li>
                                    <NavLink activeClassName="active" to="/businessLiquidations">
                                        Business Liquidations
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" to="/estateLiquidations">
                                        Estate Liquidations
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" to="/inventoryReduction">
                                        Inventory Reduction
                                    </NavLink>
                                </li>
                                {global.pluginConfiguration?.reverse_auction?.enable ? (
                                    <li>
                                        <NavLink activeClassName="active" to="/reverse-auction">
                                            Reverse Auctions
                                        </NavLink>
                                    </li>
                                ) : (
                                    ''
                                )}

                                <li className="hiw">
                                    <NavLink activeClassName="active" to="/aboutus">
                                        About Us
                                    </NavLink>
                                </li>
                                {global.pluginConfiguration?.remote_seller?.enable &&
                                isAuthenticated ? (
                                    <li>
                                        <ListItem
                                            className="become_button"
                                            button
                                            onClick={() => confirm_become_seller()}
                                        >
                                            Become Seller
                                        </ListItem>
                                    </li>
                                ) : (
                                    ''
                                )}
                                {global.storeConfigration?.disable_faq?.value !== '1' ? (
                                    <li>
                                        <NavLink activeClassName="active" to="/faq">
                                            FAQ
                                        </NavLink>
                                    </li>
                                ) : (
                                    ''
                                )}
                            </ul>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    {/* MOBILE / TAB NAVIGATION */}
                    <header className="mainHeader mobHeader noPrint customContainer d-flex justify-content-between align-items-center">
                        <div className="headLt d-flex justify-content-start">
                            <Logo className="headerLogo" />
                        </div>
                        <div className="headRt">
                            <ul
                                id={
                                    global.storeDetails.header_theme == '#000000'
                                        ? 'change-bg-font-color'
                                        : ''
                                }
                                className="d-flex justify-content-start ml-auto align-items-center"
                            >
                                <li className="headCart">
                                    <Button
                                        className="respNavBtn"
                                        onClick={toggleDrawer('top', true)}
                                    >
                                        <span className="material-icons">search</span>
                                    </Button>
                                </li>

                                {global.storeConfigration?.disable_buynow?.value !== '1' ? (
                                    <li>
                                        {global.pluginConfiguration?.dual_cart?.enable == 1 ? (
                                            <DualCart />
                                        ) : (
                                            <CartIcon />
                                        )}
                                    </li>
                                ) : null}

                                {/* {global.storeConfigration?.disable_buynow?.value !== '1' ? (
                                    <li className="headCart">
                                        <Button
                                            className="respNavBtn"
                                            onClick={() => handleRedirectInternal(history, 'cart')}
                                        >
                                            <span className="material-icons">shopping_cart</span>
                                        </Button>
                                    </li>
                                ) : null} */}

                                <li className="headCart">
                                    <Button
                                        className="respNavBtn"
                                        onClick={toggleDrawer('right', true)}
                                    >
                                        <span className="material-icons">menu</span>
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </header>

                    <React.Fragment>
                        <SwipeableDrawer
                            className="respHeaderSearch"
                            anchor={'top'}
                            open={state['top']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('top', false)}
                            onOpen={toggleDrawer('top', true)}
                        >
                            <HeaderSearch onClose={() => setState({ top: false })} />
                        </SwipeableDrawer>
                    </React.Fragment>
                    <React.Fragment>
                        <SwipeableDrawer
                            className="headerDrawer"
                            anchor={'right'}
                            open={state['right']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('right', false)}
                            onOpen={toggleDrawer('right', true)}
                        >
                            <div className="headRt respNav d-flex justify-content-start align-items-center">
                                <div className="naLogoHead d-flex justify-content-between align-items-center">
                                    <Link to="/">
                                        <img
                                            className="headerLogo"
                                            src={global?.storeDetails?.logoValue}
                                            alt={`${global?.storeDetails?.name} logo`}
                                        />{' '}
                                    </Link>
                                    <Button
                                        className="headDrawerClose"
                                        onClick={toggleDrawer('right', false)}
                                    >
                                        <span className="material-icons">clear</span>
                                    </Button>
                                </div>
                                <Divider />
                                <ul className="navRespLinks" onClick={toggleDrawer('right', false)}>
                                    {!isAuthenticated ? (
                                        <>
                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/login">
                                                    <span className="material-icons">login</span>
                                                    Login
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/sign-up">
                                                    <span className="material-icons">
                                                        how_to_reg
                                                    </span>
                                                    Register
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/" exact>
                                            <span class="material-icons">home</span> Home
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/auctions" exact>
                                            <span class="material-icons">search</span> Auctions
                                        </NavLink>
                                    </ListItem>
                                    {global.storeConfigration?.disable_buynow?.value !== '1' &&
                                    buynowitems.length > 0 ? (
                                        <ListItem button>
                                            <NavLink activeClassName="active" to="/gallery" exact>
                                                <span class="material-icons">collections</span>
                                                {global.storeConfigration?.buy_now_change_text
                                                    ?.value !== '1' &&
                                                global.storeConfigration?.buy_now_change_text?.value
                                                    ? global.storeConfigration.buy_now_change_text
                                                          .value
                                                    : global.storeConfigration?.buy_now_change_text
                                                          ?.value !== '1'
                                                    ? 'Buy Now'
                                                    : ''}
                                            </NavLink>
                                        </ListItem>
                                    ) : null}

                                    {global.pluginConfiguration?.reverse_auction?.enable ? (
                                        <ListItem button>
                                            <NavLink
                                                activeClassName="active"
                                                to="/reverse-auction"
                                                exact
                                            >
                                                <span class="material-icons">search</span> Reverse
                                                Auction
                                            </NavLink>
                                        </ListItem>
                                    ) : (
                                        ''
                                    )}
                                    {global.pluginConfiguration?.remote_seller?.enable &&
                                    isAuthenticated ? (
                                        <ListItem button onClick={() => confirm_become_seller()}>
                                            <span class="material-icons">search</span> Become Seller
                                        </ListItem>
                                    ) : (
                                        ''
                                    )}

                                    {global.storeConfigration?.external_schedule_pickup_link
                                        ?.value && (
                                        <ListItem button onClick={() => setModal(true)}>
                                            <span class="material-icons">local_shipping</span>{' '}
                                            Schedule Pickup
                                        </ListItem>
                                    )}

                                    {isAuthenticated && (
                                        <>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/mybids"
                                                >
                                                    <span className="material-icons">gavel</span>
                                                    My Bids
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/profile"
                                                >
                                                    <span className="material-icons">person</span>
                                                    Profile
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/transactions"
                                                >
                                                    <span className="material-icons">receipt</span>
                                                    Invoices
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/favorites"
                                                >
                                                    <span className="material-icons">favorite</span>
                                                    Favorites
                                                </NavLink>
                                            </ListItem>

                                            {global?.pluginConfiguration?.buyers_assurance
                                                ?.enable == 1 && (
                                                <ListItem button>
                                                    <NavLink
                                                        to="/dashboard/subscription"
                                                        activeClassName="active"
                                                    >
                                                        <span className="material-icons">
                                                            store
                                                        </span>
                                                        Subscription
                                                    </NavLink>
                                                </ListItem>
                                            )}
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/preference"
                                                >
                                                    <span class="material-icons">
                                                        notifications
                                                    </span>
                                                    Preference
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/cards"
                                                >
                                                    <span className="material-icons">
                                                        credit_card
                                                    </span>
                                                    Saved Cards
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    )}

                                    <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to="/businessLiquidations"
                                        >
                                            <span className="material-icons">groups</span>
                                            Business Liquidations
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/estateLiquidations">
                                            <span className="material-icons">groups</span>
                                            Estate Liquidations
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/inventoryReduction">
                                            <span className="material-icons">groups</span>
                                            Inventory Reduction
                                        </NavLink>
                                    </ListItem>

                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/aboutus">
                                            <span className="material-icons">groups</span>
                                            About Us
                                        </NavLink>
                                    </ListItem>
                                    {global.storeConfigration?.disable_faq?.value !== '1' ? (
                                        <ListItem button>
                                            <NavLink activeClassName="active" to="/faq">
                                                <span className="material-icons">quiz</span>
                                                FAQs
                                            </NavLink>
                                        </ListItem>
                                    ) : null}

                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/how_it_works">
                                            <span className="material-icons">groups</span>
                                            How It Works
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/termsOfService">
                                            <span className="material-icons">description</span>
                                            Terms & Conditions
                                        </NavLink>
                                    </ListItem>
                                    <Headernavmenu type="mobile" />

                                    {!isAuthenticated ? (
                                        <></>
                                    ) : (
                                        <ListItem button onClick={logoutUser}>
                                            <span className="material-icons">
                                                power_settings_new
                                            </span>
                                            Logout
                                        </ListItem>
                                    )}
                                </ul>
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>
                </>
            )}
            {global?.storeDetails?.text?.length > 0 ? (
                <div className="textSliderWrapper py-2">
                    <Swiper
                        navigation={false}
                        spaceBetween={13}
                        grabCursor={true}
                        slidesPerView={1}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        pagination={false}
                        modules={[Autoplay, EffectCoverflow, Navigation, Pagination]}
                        className="mySwiper"
                    >
                        {global?.storeDetails?.text?.map((val) => (
                            <SwiperSlide>
                                <div>
                                    <p className="mb-0">{val}</p>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            ) : (
                ''
            )}
            {userCount.itemunPaidCount ? (
                <div className="auctionHasItems noPrint">
                    <img src="/assets/svg/TrophyIcon.svg" />
                    <h3>
                        Congratulations! You have won an auction.{' '}
                        <Link
                            to={
                                global.pluginConfiguration?.dual_cart?.enable == 1
                                    ? '/auctioncart'
                                    : `/dashboard/mybids?s=1`
                            }
                        >
                            Click here
                        </Link>{' '}
                        to make the payment.
                    </h3>
                </div>
            ) : null}
            <Popup
                open={openModal}
                size="md"
                handleClose={changeDialogStatus}
                modaltitle="Schedule Pickup"
            >
                <div className="schedulePickup">
                    <iframe
                        height={600}
                        src={global.storeConfigration?.external_schedule_pickup_link?.value}
                    ></iframe>
                </div>
            </Popup>
        </>
    )
}

export default React.memo(Header)
