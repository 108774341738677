import React, { useEffect, useState, useContext, useRef } from 'react'
import './searchItems.css'
import { getParameterByName } from '../../../utils/commonFunctions'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import SearchProductList from '../../../Component/ProductCard/lotListProduct'
import Layout from '../Layout'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FilterPanel from '../../../Product/components/organisms/FilterPanel'
import Loaders from '../../../Product/components/molecules/Loaders'
import { Button, Drawer } from '@material-ui/core'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import BuyerContext from '../../../Product/context/buyer/buyerContext'
import { useLocation } from 'react-router-dom'
import { Pagination } from '@material-ui/lab'
import { load } from 'dotenv'
import { messageHandler } from '../../../Product/common/socketHandler'
import { socket, socketAuction } from '../../../Product/common/socket'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import UserActivity from '../../../utils/CommonFunctionality/UserActivity'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Rating } from '@material-ui/lab'

const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

const AuctionSearchComponent = (props) => {
    // const [cookies, setCookie] = useCookies(['user_session'])
    const location = useLocation()
    const buyerContext = useContext(BuyerContext)
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const history = useHistory()
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    const { buyer_allproducts, getAllBuyerProducts, product_view, getViewProduct } = buyerContext

    const {
        search_allproducts,
        getAllSearchProducts,
        productLocalState,
        auc_cat_value,
        getallcatanditems,
        get_all_cat,
    } = productContext
    const { setAlert } = alertContext
    const { user, isAuthenticated } = authContext
    const classes = useStyles()
    const [searchbar] = useState(new URLSearchParams(window.location.search.substring(1)).get('q'))
    const [isLoading, setLoading] = useState(true)
    const [viewProduct, setViewProduct] = useState([])
    const [locationfillter, setLocation] = useState([])
    const [page, setPage] = useState(1)
    const [totalViewProduct, setTotalViewProduct] = useState(0)
    const [listview, setlistview] = useState(false)
    const [auctionView, setAuctionView] = useState('grid')
    const [favLoad, setFavLoad] = useState(false)
    const [audio] = useState(
        global.storeConfigration?.bidding_sound?.value == 1 ? new Audio('/audio/ding.mp3') : '',
    )
    const [catClass, setCatClass] = useState(
        new URLSearchParams(window.location.search.substring(1)).get('cat'),
    )
    const [states, setStates] = useState([])
    const [searchCategories, setSearchCategories] = useState([])
    const [searchCategories2, setSearchCategories2] = useState([])
    const params = new URLSearchParams(location.search)
    const [catg] = useState(new URLSearchParams(window.location.search.substring(1)).get('cat'))
    const [rat] = useState(new URLSearchParams(window.location.search.substring(1)).get('rat'))
    const [est] = useState(new URLSearchParams(window.location.search.substring(1)).get('est'))
    const [view] = useState(new URLSearchParams(window.location.search.substring(1)).get('view'))
    const [sort] = useState(new URLSearchParams(window.location.search.substring(1)).get('sort'))
    const [slimit] = useState(
        new URLSearchParams(window.location.search.substring(1)).get('slimit'),
    )

    const active_sorts = {
        sorts: {
            sortcategoryTypes: ['2', '4', '5', '7', '8'],
            sortconditionTypes: ['2', '3', '5', '6'],
            sortsubCategoryTypes: ['1', '2', '3'],
            sortlocations: ['1', '2', '3', '18', '20'],
            sortall_notifications: [],
            sortbusiness_category: [],
            sortvat_registration: [],
        },
    }
    const formik = useFormik({
        initialValues: {
            sh_limit: slimit !== '' ? slimit : 50,
            page: 1,
            orderby: sort !== '' ? sort : '6',
            location: [],
            state: [],
            city: [],
            category: catg == 1 || catg == 0 ? [] : [catg],
            subcategory: [],
            condition: [],
            storedate: [],
            enddate: null,
            fromprice: 0,
            toprice: est !== '' ? est : 0,
            quality_rating: rat !== '' ? rat : 0,
            // rating_multiple_io: ratingValue1 !== '' ? [ratingValue1] : ['Rating 5'],
            totalpage: 5,
            contenthead5: global.pluginConfiguration?.reverse_auction?.enable ? ['0'] : [],
            lotof: '',
            searchbar: searchbar ? searchbar : '',
            from: 'auction_only',
            from_id: 1,
            closed_auction_list: 0,
            auction_io: 1,
            loc_fil: global.storeConfigration?.show_warehouse_location?.value
                ? global.storeConfigration?.show_warehouse_location?.value
                : 0,
            lot_no_fil: global.storeConfigration?.lot_sequence_order?.value
                ? global.storeConfigration?.lot_sequence_order?.value
                : 0,
            auction_city:
                productLocalState.globalLocation != 'All' ? [productLocalState.globalLocation] : [],
        },
    })

    useEffect(() => {
        setLoading(true)
        formik.setFieldValue(
            'auction_city',
            productLocalState.globalLocation == 'All' ? [] : [productLocalState.globalLocation],
        )
        getallcatanditems({
            site_id: global?.storeDetails?.site_id,
            lotof: 0,
            city: productLocalState.globalLocation == 'All' ? '' : productLocalState.globalLocation,
            from: 'get_all_items_count',
        })
    }, [productLocalState.globalLocation])

    useEffect(() => {
        if (get_all_cat.from == 'get_all_items_count') {
            if (get_all_cat.data.length > 0) {
                setSearchCategories2(get_all_cat.data.sort((a, b) => a.name.localeCompare(b.name)))
            }
        }
    }, [get_all_cat])

    const estimated_arr = [
        { id: 0, description: 'Any', value: [0] },
        { id: 1, description: 'Under $20', value: [0, 20] },
        { id: 2, description: '$20-$50', value: [20, 50] },
        { id: 3, description: '$50-$100', value: [50, 100] },
        { id: 4, description: '$100-$500', value: [100, 500] },
        { id: 5, description: 'Above $500', value: [500] },
    ]

    const ratings_arr = [
        {
            id: 0,
            description: <Rating name="read-only" value={5} precision={0.5} readOnly />,
            value: 'Rating 5',
        },
        {
            id: 1,
            description: <Rating name="read-only" value={4} precision={0.5} readOnly />,
            value: 'Rating 4',
        },
        {
            id: 2,
            description: <Rating name="read-only" value={3} precision={0.5} readOnly />,
            value: 'Rating 3',
        },
        {
            id: 3,
            description: <Rating name="read-only" value={2} precision={0.5} readOnly />,
            value: 'Rating 2',
        },
        {
            id: 4,
            description: <Rating name="read-only" value={1} precision={0.5} readOnly />,
            value: 'Rating 1',
        },
    ]

    const handleCat = (id) => {
        let currentUrl = new URL(window.location.href)

        if (currentUrl.searchParams.has('cat')) {
            currentUrl.searchParams.set('cat', id)
            window.history.replaceState({}, '', currentUrl)
        }

        setCatClass(id)
        formik.setFieldValue('category', [id])
    }

    if (global?.storeConfigration?.need_buynow_search?.value == 1) {
        var filterValues = [
            {
                title: 'View',
                type: 'radio',
                noLabel: true,
                name: 'closed_auction_list',
                int: 1,
                class: 'col-12',
                item: [
                    { id: 0, description: 'Live Items' },
                    { id: 1, description: 'Closed Items' },
                ],
                formik: formik,
            },
            {
                title: 'Type',
                type: 'radio',
                noLabel: true,
                name: 'from_id',
                int: 1,
                class: 'col-12',
                item: [
                    { id: 0, description: 'All' },
                    { id: 1, description: 'Auction' },
                    { id: 2, description: 'Buy Now' },
                ],
                formik: formik,
            },
            {
                title: 'Category',
                type: 'check',
                name: 'category',
                options: global.searchCategories.filter((main) => {
                    return true
                }),
                formik: formik,
            },
            {
                title: 'Sub category',
                type: 'check',
                name: 'subcategory',
                options: global.searchSubCategories.filter((subcat) => {
                    // if (
                    //     formik &&
                    //     formik.values &&
                    //     formik.values.category &&
                    //     formik.values.category.length
                    //         ? formik.values.category.includes(subcat.level_id.toString())
                    //         : false
                    // ) {
                    return true
                    //}
                }),
                formik: formik,
            },
        ]
    } else {
        var filterValues = [
            {
                title: 'View',
                type: 'radio',
                noLabel: true,
                name: 'closed_auction_list',
                int: 1,
                class: 'col-12',
                item: [
                    { id: 0, description: 'Live Items' },
                    { id: 1, description: 'Closed Items' },
                ],
                formik: formik,
            },
            {
                title: 'Category',
                type: 'check',
                name: 'category',
                options:
                    searchCategories2.length > 0 && global.storeConfigration?.cat_with_count?.value
                        ? searchCategories2.map((data) => {
                              return {
                                  value: `${data.category_id}`,
                                  show: `${data.name} (${data.product_cnt})`,
                                  className: data.category_id == catClass ? 'active' : '',
                                  description: `${data.name} (${data.product_cnt})`,
                                  onChange: (e) => {
                                      return handleCat(e)
                                  },
                              }
                          })
                        : global.searchCategories
                              .sort((a, b) => a.description.localeCompare(b.description))
                              .filter((main) => {
                                  return true
                              }),

                // parentcategories.map((data) => {
                //     return {
                //         value: `${data.cat_id}`,
                //         show: data.cat_name,
                //         className: data.cat_id == catClass ? 'active' : '',
                //         description: `${data.cat_name} (${data.catcount})`,
                //         onChange: (e) => {
                //             return handleCat(e)
                //         },
                //     }
                // }),
                formik: formik,
            },
            // {
            //     title: 'Sub category',
            //     type: 'check',
            //     name: 'subcategory',
            // options: global.searchSubCategories.filter((subcat) => {
            // if (
            //     formik &&
            //     formik.values &&
            //     formik.values.category &&
            //     formik.values.category.length
            //         ? formik.values.category.includes(subcat.level_id.toString())
            //         : false
            // ) {
            //         return true
            //         //}
            //     }),
            //     formik: formik,
            // },
            {
                title: 'Quality Rating',
                type: 'radio',
                noLabel: true,
                name: 'quality_rating',
                int: 1,
                class: 'col-12',
                item: ratings_arr,
                formik: formik,
            },
            {
                title: 'Estimated Retail',
                type: 'radio',
                noLabel: true,
                name: 'toprice',
                int: 1,
                class: 'col-12',
                item: estimated_arr,
                formik: formik,
            },
        ]
    }

    if (global?.storeConfigration?.show_warehouse_location?.value == 1) {
        filterValues.push({
            title: 'Location',
            type: 'check',
            class: 'col-12',
            name: 'location',
            options: locationfillter,
            formik: formik,
        })
    }

    useEffect(() => {
        // setLoading(true)
        if (search_allproducts.from === 'searchPage') {
            setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
            setTotalViewProduct(search_allproducts.total_pagecnt)
            // if (search_allproducts?.maincategory.length > 0) {
            //     setParentCategories(search_allproducts.maincategory)
            // } else {
            //     setParentCategories([])
            // }
            if (global?.storeConfigration?.show_warehouse_location?.value == 1) {
                var loc = []
                search_allproducts.locate.map((data) => {
                    loc.push({
                        description: data.name,
                        name: data.name,
                        value: data.name,
                        id: data.name,
                        active: 1,
                    })
                })
                setLocation(loc)
            }
        }
        if (
            search_allproducts?.categoriesCounts?.length > 0 &&
            searchCategories?.length == 0 &&
            global.storeConfigration?.cat_with_count?.value
        ) {
            var new_cat = global.searchCategories
            new_cat.map((value) => {
                value.catcount =
                    search_allproducts.categoriesCounts.filter(
                        (val) => parseInt(val.category_id) == parseInt(value.id),
                    ).length > 0
                        ? search_allproducts.categoriesCounts.filter(
                              (val) => parseInt(val.category_id) == parseInt(value.id),
                          )[0].CountCat
                        : 0
                if (!value.description.includes(value.catcount)) {
                    value.description = `${value.description + ' '}(${value.catcount})`
                }
            })

            setSearchCategories(new_cat)
        }
        setStates(search_allproducts.city)
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, [search_allproducts, formik.values])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }

    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        socket.on('cancelbidemitted', (data) => {
            handler(data, 'cancelbidemitted')
        })

        socketAuction.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socketAuction.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })

            socket.off('cancelbidemitted', (data) => {
                handler(data, 'cancelbidemitted')
            })

            socketAuction.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socketAuction.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
        }
    }, [])

    const onHandlePage = (event, value) => {
        setPage(value)
        setLoading(true)
        getAllSearchProducts(
            {
                ...formik.values,
                fromprice: undefined,
                toprice: undefined,
                quality_rating: undefined,
                mprice_filter: estimated_arr.filter(
                    (val) => parseInt(val.id) == parseInt(formik.values.toprice),
                )[0].value,
                rating_multiple_io: [
                    ratings_arr.filter(
                        (val) => parseInt(val.id) == parseInt(formik.values.quality_rating),
                    )[0].value,
                ],
                page: value,
            },
            'searchPage',
        )
        setLoading(false)
        window.scrollTo(0, 0)
    }

    const getAllProduct = async (bool) => {
        // setLoading(true)
        // getAllSearchProducts(formik.values, 'searchPage').then((res) => {
        //     if (res.success === 'yes' && params.get('product'))
        //         getViewProduct({ product_id: Number(params.get('product')) })
        // })
        // setTimeout(() => {
        //     setLoading(false)
        // }, 1000)
    }
    useEffect(() => {
        // setLoading(true)
        /*  if (formik.values.closed_auction_list) {
            formik.values.orderby = '7'
        } */

        if (global?.storeConfigration?.need_buynow_search?.value == 1) {
            if (parseInt(formik.values.from_id) == 0) {
                formik.values.from = ''
            } else if (parseInt(formik.values.from_id) == 1) {
                formik.values.from = 'auction_only'
            } else {
                formik.values.from = 'buynow'
            }

            params.set('at', formik.values.from_id)

            history.replace({
                pathname: location.pathname,
                search: params.toString(),
            })
        }
        let currentUrl = new URL(window.location.href)

        if (currentUrl.searchParams.has('rat')) {
            currentUrl.searchParams.set('rat', formik.values.quality_rating)
            window.history.replaceState({}, '', currentUrl)
        }
        if (currentUrl.searchParams.has('est')) {
            currentUrl.searchParams.set('est', formik.values.toprice)
            window.history.replaceState({}, '', currentUrl)
        }
        if (currentUrl.searchParams.has('sort')) {
            currentUrl.searchParams.set('sort', formik.values.orderby)
            window.history.replaceState({}, '', currentUrl)
        }
        if (currentUrl.searchParams.has('slimit')) {
            currentUrl.searchParams.set('slimit', formik.values.sh_limit)
            window.history.replaceState({}, '', currentUrl)
        }
        setPage(1)
        getAllSearchProducts(
            {
                ...formik.values,
                fromprice: undefined,
                toprice: undefined,
                quality_rating: undefined,
                mprice_filter: estimated_arr.filter(
                    (val) => parseInt(val.id) == parseInt(formik.values.toprice),
                )[0].value,
                rating_multiple_io: [
                    ratings_arr.filter(
                        (val) => parseInt(val.id) == parseInt(formik.values.quality_rating),
                    )[0].value,
                ],
                page: 1,
            },
            'searchPage',
        )
    }, [formik.values, isAuthenticated])

    useEffect(() => {
        if (
            formik.values.category ||
            formik.values.subcategory ||
            formik.values.closed_auction_list ||
            formik.values.sh_limit
        ) {
            setPage(1)
        }
    }, [
        formik.values.category,
        formik.values.subcategory,
        formik.values.closed_auction_list,
        formik.values.sh_limit,
    ])
    useEffect(() => {
        const params = new URLSearchParams(window.location.search.substring(1))
        formik.setFieldValue('searchbar', params.get('q') ? params.get('q') : '')
        formik.setFieldValue(
            'from_id',
            params.get('q') && params.get('at')
                ? params.get('at')
                : params.get('q')
                ? 0
                : params.get('at')
                ? params.get('at')
                : 1,
        )
    }, [window.location.search])

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }
    const pageOptions = [
        {
            value: '50',
            show: 'Results 50 per page',
        },
        {
            value: '75',
            show: 'Results 75 per page',
        },
        {
            value: '100',
            show: 'Results 100 per page',
        },
    ]

    const sortShow = [
        {
            value: '',
            show: 'None',
        },
        {
            value: 3,
            show: 'Current price low to high',
        },
        {
            value: 11,
            show: 'Current price high to low',
        },
        {
            value: 6,
            show: 'Time remaining',
        },
        {
            value: 2,
            show: 'Recently listed',
        },
    ]
    return (
        <Layout props={props}>
            <UserActivity page="lot_search" />
            <div className="container mt-5 position-relative">
                <div className="searchContainer">
                    <div className="searchLt">
                        <FilterPanel formik={formik} filterValues={filterValues} />
                    </div>
                    <div className="searchRt">
                        <div className="searchMiscAct d-flex justify-content-end flex-wrap my-3">
                            <div className="gridListToggle">
                                <Button
                                    className={auctionView != 'list' ? 'active' : ''}
                                    onClick={() => setAuctionView('grid')}
                                >
                                    <img src="/lots-images/loa-grid-icon.svg" alt="" />
                                </Button>
                                <Button
                                    className={auctionView === 'list' ? 'active' : ''}
                                    onClick={() => setAuctionView('list')}
                                >
                                    <img src="/lots-images/loa-list-icon.svg" alt="" />
                                </Button>
                            </div>
                            {search_allproducts &&
                                !!totalViewProduct &&
                                totalViewProduct > formik.values.sh_limit &&
                                !isLoading && (
                                    <Pagination
                                        count={Math.ceil(totalViewProduct / formik.values.sh_limit)}
                                        totalProductCount={totalViewProduct}
                                        currentPage={formik.values.page}
                                        page={page}
                                        onChange={onHandlePage}
                                    />
                                )}
                            <CustomSelect
                                label="Results per page"
                                name="resultsPerPage"
                                selectType="noBorder"
                                value={formik.values.sh_limit}
                                size="small"
                                onChange={(event, editor) => {
                                    formik.setFieldValue('sh_limit', event.target.value)
                                }}
                            >
                                {pageOptions.map((opt, optindex) => (
                                    <option value={opt.value}>{opt.show}</option>
                                ))}
                            </CustomSelect>
                            <CustomSelect
                                label="Sort by"
                                value={formik.values.orderby}
                                size="small"
                                selectType="noBorder"
                                name="orderby"
                                // shrink={search.orderby !== '' ? true : false}
                                onChange={(event, editor) => {
                                    formik.setFieldValue('orderby', event.target.value)
                                }}
                            >
                                {sortShow.map((opt, optindex) => (
                                    <option value={opt.value}>{opt.show}</option>
                                ))}
                            </CustomSelect>
                            <Button
                                className="toggleRespDrawer"
                                variant="outlined"
                                onClick={toggleDrawer('bottom', true)}
                            >
                                <span className="material-icons">tune</span>
                                Filters
                            </Button>
                        </div>
                        {isLoading ? (
                            <div>
                                <Loaders name="product_grid_view" isLoading={isLoading} loop={6} />
                            </div>
                        ) : viewProduct.length > 0 ? (
                            <SearchProductList
                                listview={auctionView == 'list'}
                                searchResult={viewProduct}
                                className="liquidationBuyCard"
                                getAllProducts={getAllProduct}
                                cardType={auctionView}
                                audio={audio}
                                enableZoomOnHover={true}
                                at={formik.values.from_id}
                            />
                        ) : (
                            <NoRecordsFound />
                        )}
                        {search_allproducts &&
                            !!totalViewProduct &&
                            totalViewProduct > formik.values.sh_limit &&
                            !isLoading && (
                                <Pagination
                                    count={Math.ceil(totalViewProduct / formik.values.sh_limit)}
                                    totalProductCount={totalViewProduct}
                                    currentPage={formik.values.page}
                                    page={page}
                                    onChange={onHandlePage}
                                />
                            )}
                    </div>
                </div>
            </div>
            <Drawer
                anchor="bottom"
                open={state['bottom']}
                className="responsiveFilterDrawer"
                onClose={toggleDrawer('bottom', false)}
                onOpen={toggleDrawer('bottom', true)}
            >
                <div
                    className={clsx(classes.list, 'bottom')}
                    role="presentation"
                    // onClick={toggleDrawer('bottom', false)}
                    onKeyDown={toggleDrawer('bottom', false)}
                >
                    <FilterPanel formik={formik} filterValues={filterValues} />
                </div>
            </Drawer>
        </Layout>
    )
}

export default AuctionSearchComponent
