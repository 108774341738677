import React, { useEffect, useState } from 'react'
import SEO from '../../../utils/CommonFunctionality/SEO'
import Layout from '../Layout'

export default function Common(props) {
    const [page, setPage] = useState({
        name: '',
        key: '',
    })
    useEffect(() => {
        const path = window.location.pathname
        if (path.includes('careers')) {
            setPage({
                name: 'Careers',
                key: 'careers',
            })
        }
        if (path.includes('sell_with_us')) {
            setPage({
                name: 'Why sell with us',
                key: 'sell_with_us',
            })
        }
        if (path.includes('help')) {
            setPage({
                name: 'Seller Tools Need Help?',
                key: 'help',
            })
        }
        if (path.includes('seller_info')) {
            setPage({
                name: 'Become a Seller',
                key: 'seller_info',
            })
        }
    }, [])
    return (
        <Layout props={props}>
            <SEO title={`${global?.storeDetails?.name} | ${page.name}`} />

            <div className="px-5 py-4 staticPgWrapper">
                {/* <h2>FAQs</h2> */}
                <span
                    dangerouslySetInnerHTML={{
                        __html: global.storeDetails[page.key],
                    }}
                ></span>
            </div>
        </Layout>
    )
}
